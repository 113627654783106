<template>
  <div>
    <h2>Manage Language Models</h2>
    <p>
      Create, edit or delete language models which can be used for NLP, prompts and other purpuses.
    </p>
    <div>
      <div>
        <ul v-if="!configurationsPending && models.length" class="models-list">
          <li v-for="model in models" :key="model.id" class="model-list-item">
            <div>
              {{ model.name }}
            </div>
            <div>
              {{ model.provider }}
            </div>
            <div>
              {{ model.capability }}
            </div>
            <div class="action-wrapper">
              <p-button class="action-icon" variant="text" color="primary" @click.stop="edit(model)">&#9998;</p-button>
            </div>
          </li>
        </ul>
      </div>
      <p-button color="primary" type="submit" @click.stop="create">Register Model</p-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-button': Button
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      configurationsPending: s => s.nlp.configurationsPending,
      models: s => s.nlp.models || []
    })
  },
  async created() {
    await this.$store.dispatch('nlp/getConfigurations');
  },
  methods: {
    async create() {
      this.$router.push({ path: `/models` });
    },
    async edit(model) {
      this.$router.push({ path: `/models/${model.id}` });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: var(--theme-on-surface);
  font-size: 1.5rem;
  font-weight: 700;
}

h2 {
  color: var(--theme-on-surface-dark);

  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
h3 {
  color: var(--theme-on-surface-dark);

  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

p {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.models-list {
  width: max-content;
  padding-bottom: 5px;
  min-width: 758px;

  .model-list-item {
    display: grid;
    grid-template-columns: 5fr 1fr 1fr 30px;
    border-top: 1px solid var(--theme-highlight);

    > div {
      border-left: 1px solid var(--theme-highlight);
      padding: 0.35rem 0.5rem;
      font-size: 0.85rem;

      &:last-child {
        border-right: 1px solid var(--theme-highlight);
      }

      &.status {
        font-weight: bold;
        justify-self: center;

        &.in-progress {
          color: #f9a825;
        }
        &.failed {
          color: #da2d2d;
        }
        &.completed {
          color: #2e815b;
        }
      }
    }

    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .action-icon {
      font-weight: 700;
      color: var(--theme-primary);
      cursor: pointer;
      justify-self: center;
    }

    &:last-child {
      border-bottom: 1px solid var(--theme-highlight);
    }
  }
}
</style>
