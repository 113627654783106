<template>
  <p-administration v-if="$hasPermission('administration.read')" />
  <p-not-found v-else />
</template>

<script>
import Administration from './../components/Administration.vue';
import NotFound from './../components/NotFound.vue';
export default {
  components: {
    'p-not-found': NotFound,
    'p-administration': Administration
  }
};
</script>
